import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getUrlParams } from '../../helpers/location';
import { renderAppointmentDateTime, parseAppointmentDateTime } from '../../helpers/date';
import { getLabOrder } from '../../actions/lab';
import { createLoadingSelector } from '../../reducers/api';
import Helmet from 'react-helmet';
import Box from '@material-ui/core/Box';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Table from '../../components/Table';
import Cell from '../../components/Table/cell';
import Layout from '../../components/Layout';
import InfoDialog from '../../components/InfoDialog';
import SkeletonLoader from '../../components/SkeletonLoader';
import ReceiptDownloadButton from '../../components/Button/ReceiptDownloadButton';

const useStyles = makeStyles(theme => ({
  orderId: {
    marginTop: -15
  },
  status_waiting_paid: {
    marginLeft: 30,
    color: theme.palette.error.main,
  },
  status_paid: {
    marginLeft: 30,
    color: theme.palette.secondary.main,
  },
}));

const paymentStatus = {
  'waiting_paid': 'รอการชำระ',
  'paid': 'ชำระแล้ว',
  'finished': 'ตรวจเสร็จแล้ว'
}

export default ({ location }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showInfoDialog, setShowInfoDialog] = React.useState();
  const query = getUrlParams(location.search);
  const isLoading = useSelector(createLoadingSelector(['lab/order/get']));
  const order = useSelector(state => state.lab.orderById[query.id]);

  React.useEffect(() => {
    if (query.id) {
      dispatch(getLabOrder(query.id));
    }
  }, [query.id]);

  if (!order) {
    return (
      <Layout hideAppBar>
        <SkeletonLoader />
      </Layout>
    );
  }

  const { time_slot, address, package_items, payment_status, status, result } = order;
  const package_item = package_items[0];

  return (
    <Layout hideAppBar>
      <Helmet>
        <title>โปรแกรมตรวจสุขภาพ - MorOnline</title>
      </Helmet>
      <InfoDialog
        open={showInfoDialog}
        onClose={() => setShowInfoDialog()}
        onConfirm={() => setShowInfoDialog()}
        disableBackdropClick={true}
        content={showInfoDialog}
      />
      <Box p={2} overflow="scroll">
        <h2>ตรวจสุขภาพ</h2>
        <p className={classes.orderId}>
          <small># {order.id}</small>
          {
            status === 'finished' ? (
              <small className={classes[`status_paid`]}>{paymentStatus[status]}</small>
            ) : (
              <small className={classes[`status_${payment_status}`]}>{paymentStatus[payment_status]}</small>
            )
          }
        </p>
        {
          package_item && (
            <Table>
              <TableBody>
                <TableRow>
                  <Cell>วันที่นัด</Cell>
                  <Cell>{renderAppointmentDateTime(parseAppointmentDateTime(time_slot.date, time_slot.start_time))}</Cell>
                </TableRow>
                <TableRow>
                  <Cell>ที่อยู่</Cell>
                  <Cell>{address.name.substring(0, 50)}...</Cell>
                </TableRow>
                {
                  order.prerequisite && (
                    <TableRow>
                      <Cell>การเตรียมตัว</Cell>
                      <Cell>{order.prerequisite}</Cell>
                    </TableRow>
                  )
                }
                <TableRow>
                  <Cell>หมายเหตุ</Cell>
                  <Cell>ในกรณีต้องการขอเลื่อนวันให้บริการจะต้องแจ้ง ล่วงหน้าอย่างน้อย 1 วัน</Cell>
                </TableRow>
              </TableBody>
            </Table>
          )
        }
        {
          status === 'finished' && !!result.attachment && (
            <>
              <h3>ผลการตรวจ</h3>
              <TableRow>
                <Cell>
                  <a href={result.attachment} target="_blank">คลิกที่นี่เพื่อดูผลตรวจ</a>
                  <br/>
                  <small style={{ color: '#ff0000' }}>
                    รหัส: วันเดือนปีเกิด(พ.ศ.) ตัวอย่างเช่น 22102550
                  </small>
                </Cell>
              </TableRow>
            </>
          )
        }
        <h3>รายการตรวจ</h3>
        {
          package_items.map((val, index) => {
            const { labpackage } = val;
            return (
              <Table key={index}>
                <TableBody>
                  <TableRow>
                    <Cell colSpan={2}><b>{labpackage.name}</b></Cell>
                  </TableRow>
                  {
                    labpackage.checkup_programs.length > 1 && (
                      <TableRow>
                        <Cell>รายการตรวจ</Cell>
                        <Cell>
                          <a href="#" onClick={(e) => {
                            e.preventDefault();
                            setShowInfoDialog(
                              <ul>
                                {labpackage.checkup_programs.map((val1, index) => {
                                  return (
                                    <li key={index}><small>{val1.name}</small></li>
                                  );
                                })}
                              </ul>
                            )
                          }}>คลิกที่นี่เพื่อดูรายละเอียด</a>
                        </Cell>
                      </TableRow>
                    )
                  }
                </TableBody>
              </Table>
            )
          })
        }
        {order.receipt &&
          <Box mt={1}>
            <ReceiptDownloadButton
              fileUrl={order.receipt.fileUrl}
              filename={order.receipt.id} />
          </Box>
        }
      </Box>
    </Layout>
  );
}
